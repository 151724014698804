import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Layout } from "./components";
import { routeNames } from "./routes";
import { Home } from "./pages/Home";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path={routeNames.home} element={<Home />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
