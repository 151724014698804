import { useState } from "react";
import { FaArrowRight, FaDiscord, FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiGitbook } from "react-icons/si";
import { TbBrandGithubFilled } from "react-icons/tb";
import { Popover } from "react-tiny-popover";

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="w-full max-w-screen-xl mx-auto px-4 sm:px-0 py-4 sm:py-2 my-0 sm:my-1">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <img
            src="/assets/img/token/Ouroboros.png"
            // className="sm:block hidden"
            width={54}
            alt=""
          />
        </div>

        <div className="flex sm:gap-4 gap-2">
          <a
            className="px-4 py-1 cursor-pointer border border-green-400 border-solid rounded-full flex items-center gap-2 transition-colors duration-300 bg-green-400 text-black hover:bg-green-600 group"
            href="https://dexscreener.com/ethereum/0x9eb7ee451623d4ebf951254edb2958774dfb7446"
            target="_blank"
            rel="noreferrer"
          >
            Buy
            <FaArrowRight />
          </a>

          <Popover
            isOpen={showMenu}
            positions={["bottom"]}
            padding={10}
            align="end"
            onClickOutside={() => setShowMenu(false)}
            containerStyle={{ zIndex: "1001" }}
            content={
              <div className="rounded-2xl border border-solid border-gray-700 opacity-1 flex flex-col bg-black text-white mx-4 sm:mx-0 p-1">
                <div className="flex flex-col border-b border-gray-700 rounded-2xl">
                  <div className="flex items-center w-full gap-4 mt-8 px-4">
                    <img
                      src="/assets/img/token/Ouroboros.png"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <div
                      className="text-xl sm:text-xl bg-gradient-to-r from-gray-300 via-green-400 to-emerald-700 inline-block text-transparent bg-clip-text text-center"
                      style={{ lineHeight: "1.2" }}
                    >
                      The OURO Network
                    </div>
                  </div>

                  <div className="mt-4 px-4">
                    An exclusive community who are building brands and boosting
                    revenue together.
                  </div>

                  <div className="flex justify-center my-4">
                    <a
                      className="w-fit cursor-pointer border border-green-400 border-solid rounded-full px-8 py-2 flex items-center gap-2 transition-colors duration-300 bg-transparent hover:bg-green-400 group"
                      href="https://discord.com/invite/Dnek83Ej"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-green-400 transition-colors duration-300 bg-transparent group-hover:text-white">
                        Join Discord
                      </span>
                      <FaDiscord size={30} />
                    </a>
                  </div>
                </div>

                <div className="menu-items bg-black p-8 flex flex-col rounded-2xl">
                  <div className="menu-nav flex flex-wrap gap-8 bg-black">
                    <a
                      className="hover:text-green-400"
                      href="#Token"
                      onClick={() => setShowMenu(false)}
                    >
                      Token
                    </a>
                    <a
                      className="hover:text-green-400"
                      href="#ecosystem"
                      onClick={() => setShowMenu(false)}
                    >
                      Ecosystem
                    </a>
                    <a
                      className="hover:text-green-400"
                      href="#tech-capabilities"
                      onClick={() => setShowMenu(false)}
                    >
                      Technical Capabilities
                    </a>
                    <a
                      className="hover:text-green-400"
                      href="#faq"
                      onClick={() => setShowMenu(false)}
                    >
                      FAQ
                    </a>
                    <a
                      className="hover:text-green-400"
                      href="#roadmap"
                      onClick={() => setShowMenu(false)}
                    >
                      Roadmap
                    </a>
                    <a
                      className="hover:text-green-400"
                      href="#vision"
                      onClick={() => setShowMenu(false)}
                    >
                      Vision
                    </a>
                  </div>
                </div>

                <div className="external border-t border-solid border-gray-700 rounded-2xl p-4">
                  <div className="flex justify-between gap-4">
                    <a
                      className="w-full my-4 cursor-pointer border border-gray-700 border-solid rounded-full px-4 py-3 flex justify-center items-center gap-2 transition-colors duration-300 bg-transparent hover:bg-white group"
                      href="Https://ouroscan.io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-white transition-colors duration-300 bg-transparent group-hover:text-black">
                        Ouro Scan
                      </span>
                    </a>
                    <a
                      className="w-full my-4 cursor-pointer border border-gray-700 border-solid rounded-full px-4 py-3 flex justify-center items-center gap-2 transition-colors duration-300 bg-transparent hover:bg-white group"
                      href="https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/snake-tokenomics/snake-tokens-specifications"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-white transition-colors duration-300 bg-transparent group-hover:text-black">
                        Whitepaper
                      </span>
                    </a>
                  </div>

                  <div className="flex justify-end gap-2 py-4">
                    <a
                      href="https://t.me/ouronetwork"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaTelegramPlane
                        size={30}
                        className="bg-gray-600 text-gray-800 p-1 rounded-sm"
                      />
                    </a>
                    <a
                      href="https://github.com/AncientHodler/DemiPact"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TbBrandGithubFilled
                        size={30}
                        className="bg-gray-600 text-gray-800 p-1 rounded-sm"
                      />
                    </a>
                    <a
                      href="https://Twitter.com/ouronetwork"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaXTwitter
                        size={30}
                        className="bg-gray-600 text-gray-800 p-1 rounded-sm"
                      />
                    </a>
                    <a
                      href="https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/snake-tokenomics/snake-tokens-specifications"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiGitbook
                        size={30}
                        className="bg-gray-600 text-gray-800 p-1 rounded-sm"
                      />
                    </a>
                  </div>
                </div>
              </div>
            }
          >
            <button
              className="px-4 py-2 border border-solid border-gray-700 rounded-full flex items-center"
              onClick={() => setShowMenu(!showMenu)}
            >
              <span
                className={`menuIcon relative min-w-[40px] h-[24px] box-border ${
                  showMenu && "active"
                }`}
              ></span>
              <span className="text-sm ps-1 pt-1">MENU</span>
            </button>
          </Popover>
        </div>
      </div>
    </div>
  );
};
