import { Fragment, lazy, Suspense } from "react";
const TokenInfo = lazy(() => import("./TokenInfo"));
const EcoSystem = lazy(() => import("./EcoSystem"));
const Tokenomics = lazy(() => import("./Tokenomics"));
const Pioneer = lazy(() => import("./Pioneer"));
const Roadmap = lazy(() => import("./Roadmap"));
const Revolution = lazy(() => import("./Revolution"));

export const Home = () => {
  return (
    <Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <TokenInfo />
        <EcoSystem />
        <Tokenomics />
        <Pioneer />
        <Roadmap />
        <Revolution />
      </Suspense>
    </Fragment>
  );
};
