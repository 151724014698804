import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col bg-black text-white">
      <Header />
      <main className='min-h-[100vh]'>{children}</main>
      <Footer />
    </div>
  );
};
