import { FaDiscord, FaHeart, FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiGitbook } from "react-icons/si";
import { TbBrandGithubFilled } from "react-icons/tb";

export const Footer = () => {
  return (
    <div className="noise pt-[48px]">
      <div className="w-full max-w-screen-xl mx-auto px-4 sm:px-0 py-4 sm:py-2 my-0 sm:my-1">
        <div className="foutter-section flex flex-col gap-4 sm:flex-row justify-between sm:py-8">
          <div className="left-section">
            <div className="flex items-center gap-4">
              {" "}
              <img src="/assets/img/token/Ouroboros.png" alt="" width={56} />
              <p
                className="text-2xl sm:text-4xl bg-gradient-to-r from-gray-300 via-green-400 to-emerald-700 inline-block text-transparent bg-clip-text text-center"
                style={{ lineHeight: "1.2" }}
              >
                The OURO Network
              </p>
            </div>
            <p className="text-xl max-w-[300px] mt-4">
              Building the Future on Blockchain Fast. Secure. Private.
            </p>
          </div>
          <div className="right-section flex flex-row sm:flex-col gap-2 justify-center">
            <div className="flex gap-2">
              <a
                href="https://Twitter.com/ouronetwork"
                target="_blank"
                rel="noreferrer"
              >
                <FaXTwitter
                  size={36}
                  className="bg-gray-600 text-gray-800 p-2 rounded-lg"
                />
              </a>
              <a
                href="https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/snake-tokenomics/snake-tokens-specifications"
                target="_blank"
                rel="noreferrer"
              >
                <SiGitbook
                  size={36}
                  className="bg-gray-600 text-gray-800 p-2 rounded-lg"
                />
              </a>
              <a
                href="https://github.com/AncientHodler/DemiPact"
                target="_blank"
                rel="noreferrer"
              >
                <TbBrandGithubFilled
                  size={36}
                  className="bg-gray-600 text-gray-800 p-2 rounded-lg"
                />
              </a>
            </div>
            <div className="flex gap-2">
              <a
                href="https://t.me/ouronetwork"
                target="_blank"
                rel="noreferrer"
              >
                <FaTelegramPlane
                  size={36}
                  className="bg-gray-600 text-gray-800 p-2 rounded-lg"
                />
              </a>

              <a
                href="https://discord.com/invite/Dnek83Ej"
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord
                  size={36}
                  className="bg-gray-600 text-gray-800 p-2 rounded-lg"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 justify-between items-center sm:py-4 py-3">
          <div className="flex gap-2">
            <a href="/" className="text-gray-500">
              Help Center
            </a>
            <a href="/" className="text-gray-500">
              Terms of Service
            </a>
            <a href="/" className="text-gray-500">
              Legal
            </a>
            <a href="/" className="text-gray-500">
              Contact
            </a>
          </div>
          <div className="flex gap-2">
            ©2024 OuroNetwork MADE WITH{" "}
            <span style={{ color: "red" }}>
              <FaHeart />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
